import React from 'react';
import Link from 'gatsby-link';
import styles from './header.module.scss';

export default class Header extends React.Component {

  render() {
    return (
      <div className={styles.header}>
        <div
          className={styles.wrapper}>
          <h2>
            <Link
              to="/">
              Ramon Recuero
            </Link>
          </h2>
          <div className={styles.links}>
            <Link
              to="/grip"
              className={styles.link}>
              More about Grip
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

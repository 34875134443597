import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import './index.css';

import Header from '../components/Header'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="Ramon Recuero"
      meta={[
        { name: 'description', content: 'Ramon Recuero' },
        { name: 'keywords', content: 'Ramon Recuero, Y Combinator, YC, crypto, startups' },
      ]} >
      <script type="text/javascript" src="https://gumroad.com/js/gumroad.js"></script>
      <script type="text/javascript" src="//downloads.mailchimp.com/js/signup-forms/popup/unique-methods/embed.js" data-dojo-config="usePlainJson: true, isDebug: false"></script>
    </Helmet>
    <Header />
    <div
      style={{
        margin: '0 auto',
        display: 'flex',
        padding: '0',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 0,
      }}
    >
      {children}
    </div>
  </div>
)

TemplateWrapper.propTypes = {
  children: PropTypes.func,
}

export default TemplateWrapper
